'use strict';

(function () {
  window.HELP_IMPROVE_VIDEOJS = false;
  // var webinar = videojs('webinar-modal-video-id-player');

  window.onWebinarSubmit = function (token) {
    // var response = grecaptcha.getResponse(window.recaptchaW);
    var response = 'pass';

    if (
      $('#frm_web_lead_field_email').val() == '' ||
      $('#frm_web_lead_field_first_name').val() == ''
    ) {
      return;
    }

    if (response.length > 0) {
      var formData = {
        frm_lead_field_email: $.trim($('#frm_web_lead_field_email').val()),
        frm_lead_field_first_name: $.trim(
          $('#frm_web_lead_field_first_name').val()
        ),
        frm_lead_field_webinar: $.trim($('#frm_web_lead_field_webinar').val()),
        'g-recaptcha-response': response,
      };

      //var text = $('#webinar-popup-form-submit').text();
      var bg = $('#webinar-popup-form-submit').css('background-color');
      var text = $('#webinar-popup-form-submit').text();

      $('#webinar-popup-form-submit').text('Please wait...');
      $('#webinar-popup-form-submit')
        .prop('disabled', true)
        .css('opacity', 0.5);

      $.ajax({
        url: '/forms/webinar/?token=dd903a8d-5927-45e5-9d52-63960233ba13',
        type: 'post',
        data: formData,
        success: function (r) {
          // Set the cookie
          webinarSetCookie();
          // setTimeout(function () {
          $('#webinar-popup-form-submit').css('opacity', 1);
          $('#webinar-popup-form-submit').css('background-color', '#28a745');
          $('#webinar-popup-form-submit').text(' THANKS!');

          // Decrypt the data URL
          let data = $('#webinar-stream').data('src');

          if (data) {
            let data = webinarDecryptData($('#webinar-stream').data('src'));
            // Set the new decrypted data elements
            $('#webinar-stream').attr('data-src', data);
            $('#webinar-stream').attr('data-protected', 0);

            $('.resource-link').each(function (index) {
              let data = webinarDecryptData($(this).data('src'));
              // alert($(this).data('src'));
              // Set the new decrypted data elements
              $(this).attr('data-src', data);
              $(this).attr('href', data);
              $(this).attr('data-protected', 0);
              $(this).off('click');
            });

            $('html, body').animate({
              scrollTop: $('.content-webinar').offset().top,
            });

            // Show the webinar
            showWebinar(webinarURL('#webinar-stream'));
            // webinarModal(webinarURL('#webinar'));
            // }, 2000);
          }

          setTimeout(function () {
            $('#webinar-popup-form-submit')
              .prop('disabled', false)
              .css('opacity', 1)
              .css('background-color', bg);
            $('#webinar-popup-form-submit').text(text);
            // Hide popup
            $('#webinar_popup').modal('hide');
          }, 2000);
        },
        error: function (xhr, textStatus, errorThrown) {
          $('#webinar-popup-form-submit').text('Ooops. There was an error!');
          $('#webinar-popup-form-submit')
            .prop('disabled', false)
            .css('opacity', 1);
        },
      });
    } else {
      // grecaptcha.reset(window.recaptchaW);
      $('#webinar-popup-form-submit').prop('disabled', true);
      $('#webinar-popup-form-submit').text('Ooops. There was an error!');
    }
  };

  //   function hex2a(hex) {
  //     var str = '';

  //     for (var i = 0; i < hex.length; i += 2) {
  //       var v = parseInt(hex.substr(i, 2), 16);
  //       if (v) str += String.fromCharCode(v);
  //     }

  //     return str;
  //   }

  window.showWebinar = function (data) {
    /** Replace Event video thumbnail with video IFrame */

    $('#webinar-container').addClass('fade-out-container');

    $('.fade-out-container').on(
      'animationend webkitAnimationEnd oAnimationEnd',
      function () {
        var webinar =
          '<video-js id="webinar-video-id-player" class="fade-in-stream video-js" width="100%" height="auto">  <source src="#data" type="video/mp4" /> <p class="vjs-no-js">To view this webinar, please enable JavaScript, and consider upgrading to a web browser that supports it</p> </video-js>';
        // $('#webinar-video-id-player').addClass('fade-in-stream');

        webinar = webinar.replace('#data', data);
        $('#webinar-container').replaceWith(webinar);

        // var overrideNative = false;

        var webinar_stream = videojs('webinar-video-id-player', {
          controls: true,
          autoplay: false,
          preload: 'auto',
          playsinline: true,
          fluid: true,
          // html5: {
          //   hls: {
          //     overrideNative: overrideNative,
          //   },
          //   nativeVideoTracks: !overrideNative,
          //   nativeAudioTracks: !overrideNative,
          //   nativeTextTracks: !overrideNative,
          // },
        });

        // webinar_stream.src(data);
        webinar_stream.play(); //   }
      }
    );
  };

  // window.webinarModal = function (data) {
  //   webinar.src(data);
  //   $('#webinar_modal').modal('show');
  // };

  window.webinarPopUp = function (data) {
    $('#webinar_popup').modal('show');
  };

  var _0xb6b5 = [
    '',
    '\x6C\x65\x6E\x67\x74\x68',
    '\x73\x75\x62\x73\x74\x72',
    '\x66\x72\x6F\x6D\x43\x68\x61\x72\x43\x6F\x64\x65',
  ];

  function _0x73ba(_0x1effx2) {
    var _0x1effx3 = _0xb6b5[0];
    for (var _0x1effx4 = 0; _0x1effx4 < _0x1effx2[_0xb6b5[1]]; _0x1effx4 += 2) {
      var _0x1effx5 = parseInt(_0x1effx2[_0xb6b5[2]](_0x1effx4, 2), 16);
      if (_0x1effx5) {
        _0x1effx3 += String[_0xb6b5[3]](_0x1effx5);
      }
    }
    return _0x1effx3;
  }

  var _0x4f6c = [
    '',
    '\x6A\x6F\x69\x6E',
    '\x72\x65\x76\x65\x72\x73\x65',
    '\x73\x70\x6C\x69\x74',
  ];

  function _0x722d(_0xe413x2) {
    _0xe413x2 = _0xe413x2[_0x4f6c[3]](_0x4f6c[0])
      [_0x4f6c[2]]()
      [_0x4f6c[1]](_0x4f6c[0]);
    _0xe413x2 = atob(_0xe413x2);
    _0xe413x2 = _0x73ba(_0xe413x2);
    return _0xe413x2;
  }

  //   function decrypt(data) {
  //     data = data.split('').reverse().join('');
  //     data = atob(data);
  //     data = _0x73ba(data);
  //     return data;
  //   }

  function webinarSetCookie() {
    var expires = '';
    var date = new Date();

    date.setTime(date.getTime() + 7305 * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();

    document.cookie = 'eth_webinar' + '=' + (date || '') + expires + '; path=/';
  }

  function webinarGetCookie() {
    var nameEQ = 'eth_webinar' + '=';
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  function webinarDecryptData(data) {
    return _0x722d(data);
  }

  function webinarProtected(id) {
    return $(id).attr('data-protected') == 1 ? true : false;
  }

  function webinarURL(id) {
    return $(id).attr('data-src');
  }

  $(document).ready(function () {
    // $('#webinar').click(function (e) {
    //   e.preventDefault();
    //alert(webinarURL('#webinar'));
    // });
    if (webinarProtected('#webinar-stream')) {
      if (webinarGetCookie() != null) {
        let data = webinarDecryptData($('#webinar-stream').data('src'));
        $('#webinar-stream').attr('data-src', data);
        $('#webinar-stream').attr('data-protected', 0);

        $('.resource-link').each(function (index) {
          let data = webinarDecryptData($(this).data('src'));
          // alert($(this).data('src'));
          // Set the new decrypted data elements
          $(this).attr('data-src', data);
          $(this).attr('href', data);
          $(this).attr('data-protected', 0);
          // $(this).off('click');
        });
      }
    }
  });

  $(document).ready(function () {
    $('#webinar-container').on('click', function (e) {
      e.preventDefault();

      if (webinarProtected('#webinar-stream')) {
        webinarPopUp();
      } else {
        showWebinar(webinarURL('#webinar-stream'));
      }
    });
  });

  $(document).ready(function () {
    $('.webinar-resource').on('click', function (e) {
      if (webinarProtected('.resource-link')) {
        e.preventDefault();
        webinarPopUp();
      }
    });
  });

  // $(document).ready(function () {
  //   $('#webinar_modal').on('shown.bs.modal', function () {
  //     // var webinar = videojs('webinar-modal-video-id-player');
  //     webinar.play();
  //   });
  // });

  // $(document).ready(function () {
  //   $('#webinar_modal').on('hidden.bs.modal', function () {
  //     // var webinar = videojs('webinar-modal-video-id-player');
  //     webinar.reset();
  //   });
  // });

  $(document).ready(function () {
    $('#webinar-popup-form').on('submit', function (e) {
      // Check for SPAM
      e.preventDefault();

      if ($('#frm_web_lead_field_first_name_x').val() == '') {
        // grecaptcha.ready(function () {
        //   grecaptcha.execute(window.recaptchaW);
        // });
        window.onWebinarSubmit('pass');
      } else {
        $('#webinar-popup-form-submit').text('Ooops. There was an error!');
      }
    });
  });

  $(document).ready(function () {
    $('#webinar_popup').on('shown.bs.modal', function (e) {
      //get data-id attribute of the clicked element
      var title = $(e.relatedTarget).data('title');
      var button = $(e.relatedTarget).data('button');

      if (title) {
        $('#webinar_popup_title').text(title);
      }

      if (button) {
        $('#webinar-popup-form-submit').text(button);
      }
    });
  });
})();
